@import "../../tokens/tokens.scss";

.banner-premio-ebit {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    margin-bottom: -150px;
  }

  &__img-premio1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > img {
      height: 400px;
      width: 100%;
      object-fit: cover;

      @media (max-width: 500px) {
        display: none;
      }
    }

    & div {
      margin-top: -400px;

      & img {
        width: 850px;
        height: 400px;
        object-fit: cover;

        @media (max-width: 850px) {
          width: 500px;
        }

        @media (max-width: 500px) {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: initial;
          margin-top: 100px;
          margin-bottom: -200px;
        }
      }
    }
  }
}

.about-ebit-text {
  font-size: $text-size-primary;
  margin: 70px auto;

  & h2 {
    font-size: $title-primary;
    font-family: $font-family-primary;
    color: $color-primary-red-nielsen;
    text-align: left;
    padding-top: 50px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  & div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 20px;

    & img {
      width: 300px;
      margin: 20px;
    }

    & p {
      width: 650px;
      margin: 20px;
    }
  }
}

.sorteio {
  font-size: $text-size-primary;

  & h2 {
    font-size: 35px;
    font-family: $font-family-primary;
    color: $color-primary-red-nielsen;
    text-align: left;
    padding-top: 50px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  &__passos {
    display: flex;
    flex-wrap: wrap;
    margin: 50px 20px 50px 20px;
    align-items: flex-start;
    justify-content: center;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-radius: 0.8rem;
    border-color: $color-secondary-gray-nielsen;
    -webkit-box-shadow: 7px 10px 7px 0px #d6d6d6;
    -moz-box-shadow: 7px 10px 7px 0px #d6d6d6;
    box-shadow: 7px 10px 7px 0px #d6d6d6;

    & .passos-detalhes {
      background-color: #343a40;
      color: white;
      border-radius: 0.3rem;
      max-width: 300px;
      height: 150px;
      margin-bottom: 20px;

      & h6 {
        background-color: $color-primary-red-nielsen;
        border-radius: 0.3rem 0.3rem 0 0;
        padding: 10px;
        text-transform: uppercase;
      }

      & p {
        padding: 10px;
      }
    }

    & .passos-infos {
      max-width: 650px;

      & p {
        margin: 20px;
      }
    }
  }
}

.ganhadores {
  font-size: $text-size-primary;

  & h2 {
    font-size: $title-primary;
    font-family: $font-family-primary;
    color: $color-primary-red-nielsen;
    text-align: left;
    padding-top: 50px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  & div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 20px;

    & img {
      width: 300px;
      margin: 20px;
    }

    & p {
      width: 650px;
      margin: 20px;
    }
  }
}

.medal-true {
  font-size: $text-size-primary;

  & h2 {
    font-size: $title-primary;
    font-family: $font-family-primary;
    color: $color-primary-red-nielsen;
    text-align: left;
    padding-top: 50px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  & div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 20px;

    & img {
      width: 300px;
      margin: 20px;
    }

    & p {
      width: 650px;
      margin: 20px;
    }
  }
}

.company {
  margin-bottom: 70px;

  & h2 {
    font-size: 25px;
    font-family: $font-family-primary;
    color: $color-primary-red-nielsen;
    text-align: left;
    padding-top: 50px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    & a {
      background-color: black;
      font-size: $text-size-primary;
      color: white;
      padding: 15px 20px;
      border-radius: 2.5rem;
      -webkit-box-shadow: 7px 10px 7px 0px #d6d6d6;
      -moz-box-shadow: 7px 10px 7px 0px #d6d6d6;
      box-shadow: 7px 10px 7px 0px #d6d6d6;
    }
  }
}

.login {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;

  & h2 {
    font-size: 25px;
    font-family: $font-family-primary;
    color: $color-primary-red-nielsen;
    text-align: left;
    padding-top: 50px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  & img {
    margin-top: 20px;
  }
}

.regulamento-sorteio {
  font-family: Montserrat;
  
  & .t-regulation{
    margin-top: 50px;

    & .t1-regulation{
      font-size: 25px;
    }
  } 
}

.banner-NIQ-sorteio {
  background: url("../../assets/imagens/BannerRegulamentoPremio.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 430px;
  margin-top: -75px;
  display: flex;
  align-items: center;

  & div {
    display: flex;
    flex-direction: column;
    color: white;

    & h2 {
      font-size: 40px;
      font-family: Montserrat !important;
      font-weight: 700;
      margin-top: 70px;
    }

    & h3 {
      font-family: Montserrat !important;
      font-size: 30px;
      font-weight: 700;
    }
  }
}
