@import "../../tokens/tokens.scss";

// Banner Página Principal Websshoppers
.banner-webshoppers {
  background: url("../../assets/imagens/BG-test.PNG");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;
  object-fit: cover;

  &__text-bn-ws-43 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: white;
    padding-top: 92px;

    .descripition-ws-43 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-bottom: 1px solid;
      width: 100%;

      h1 {
        font-size: 50px;
        font-family: $font-family-secondary;

        @media (max-width: 450px) {
          font-size: 40px;
        }
      }
    }
  }

  .img-and-btn {
    @media (max-width: 450px) {
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      justify-content: flex-end;
      margin-top: 15px;
    }
    a {
      font-family: $font-family-secondary;
      font-size: 20px;
      font-weight: 500;
      border: 1px solid red;
      padding: 10px 20px;
      color: white;
      text-decoration: none;
      background-color: #dc0015;
      transition: all 0.25s;

      @media (max-width: 404px) {
        font-size: 16px;
      }

      &:hover {
        background-color: white;
        color: #dc0015;
      }
    }
  }

  img {
    width: 180px;
    margin-right: -28px;
  }
}

.webshoppers-plan {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;

  h2 {
    text-align: left;
  }

  &__pricing {
    display: flex;
    margin: 10px;
    min-height: 450px;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      text-decoration: none;
      color: black;
      border: solid 1px;
      border-color: $color-secondary-gray-nielsen;
      margin: 20px;
      padding: 10px;
      max-width: 325px;
      transition: all 0.38s;
      -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);

      @media (max-width: 427px) {
        width: 280px;
      }

      h3 {
        font-weight: 300;
      }

      .value-report {
        p {
          font-size: 30px;
          font-weight: 600;

          span {
            font-size: 15px;
          }
        }
      }

      div {
        padding: 0 25px;
        text-align: start;
        line-height: 30px;

        ul {
          list-style-type: none;
        }
      }

      &:hover {
        border-color: $color-primary-gray-nielsen;
        cursor: pointer;
        color: black;
        text-decoration: none;
      }

      & img {
        width: 300px;
        margin-bottom: 20px;

        @media (max-width: 450px) {
          width: 260px;
        }
      }
    }
  }
}

.ws-vs-42 {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;

  // @media (max-width: 768px) {
  //     flex-wrap: wrap;
  //     align-items: center;
  //     justify-content: center;
  // }

  h2 {
    margin-bottom: 35px;
    text-align: left;
  }

  .old-reports-layout {
    display: flex;
    //align-self: baseline;
    flex-wrap: wrap;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .reports-vs-42 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 210px;
      height: 255px;
      margin: 25px;
      border: 1px solid;
      border-color: $color-secondary-gray-nielsen;
      padding: 10px;
      transition: all 0.38s;
      -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);

      &:hover {
        border-color: $color-primary-gray-nielsen;
      }

      img {
        width: 190px;
        margin-bottom: 15px;
      }

      h4 {
        font-size: 16px;
        font-weight: 400;
      }

      .old-pricing {
        font-size: 20px;
        font-weight: 600;

        span {
          font-size: 15px;
          font-weight: 300;
        }
      }

      .pay-old-report {
        border: 1px solid;
        border-color: $color-secondary-gray-nielsen;
        padding: 5px 20px;
        text-decoration: none;
        color: black;
        transition: all 0.38s;
        font-weight: 600;

        &:hover {
          background-color: $primary-green-niq !important;
          cursor: pointer;
        }
      }
    }
  }
}

.ws-descripition {
  display: flex;
  //align-items: center;
  flex-direction: column;

  margin: 100px auto;

  h2 {
    margin-bottom: 35px;
    text-align: left;
  }
}

.img-footer-ws-43 {
  margin: 50px auto 20px auto;

  @media (max-width: 768px) {
    display: none;
  }

  & img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.patrocinador-ws-43 {
  display: flex;
  margin: 100px auto;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-right: 85px;
    p {
      font-family: $font-family-secondary;
      font-weight: 500;
    }

    img {
      width: 200px;
      @media (max-width: 1000px) {
        margin-bottom: 30px;
      }
    }
  }
}

// Banner Página Websshoppers-Premium
.banner-webshoppers-premium {
  background: url("../../assets/imagens/BG-test.PNG");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  object-fit: cover;

  &__text-bn-ws-pr-43 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: white;
    padding-top: 67px;

    .descripition-ws-premium-43 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-bottom: 1px solid;
      width: 100%;
      margin-top: -25px;

      h1 {
        font-size: 50px;
        font-family: $font-family-secondary;

        @media (max-width: 421px) {
          font-size: 40px;
        }
      }
    }

    .vs-title {
      font-size: 25px;
      color: #ffc107;
    }
  }

  img {
    width: 180px;
    margin-top: -18px;
    margin-right: -28px;
  }
}

.content-ws-pr-43 {
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin: 80px auto 0px auto;

  .report-info {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    h2 {
      margin-top: 50px;
      color: #ffc107;
    }

    img {
      width: 250px;
      //margin-top: 50px;
      margin-right: 20px;
    }
  }

  .acc-test {
    margin-bottom: 50px;
  }

  .btn-toggle {
    text-decoration: none;
    padding: 10px 20px;
    width: 100%;
    text-align: start;
    margin-bottom: 30px;
    transition: all 0.5s;
    color: $shades-gray-80;

    &:hover {
      //border: 1px solid;
      //border-color: #ffc1079c;
      background-color: black;
      color: #ffc107;
      //text-align: center;
    }

    &:focus {
      box-shadow: none;
      border: 1px solid;
      border-color: #ffc107;
      //text-align: end;
    }

    h3 {
      border-bottom: 1px solid;
      border-color: $color-secondary-gray-nielsen;
      font-size: 25px;
    }
  }

  .report-itens {
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-start;

    .descripition-itens {
      display: flex;
      flex-direction: column;
      width: 330px;
      margin: 17px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      border-color: $color-secondary-gray-nielsen;
      padding: 7px;
      -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      box-shadow: 5px 7px 7px 0px rgba(214, 214, 214, 1);
      //background-color: black;
      //color: white;

      h4 {
        font-size: 20px;
        font-weight: 600;
        color: $shades-gray-60;
        //color: #ffc107;
        //background-color: black;
      }

      p {
        font-size: 17px;
      }
    }
  }

  .btn-purchase {
    text-align: center;
    margin: 30px auto 55px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    a {
      color: black;
      font-size: 25px;
      border: 1px solid;
      text-decoration: none;
      padding: 7px 35px;
      transition: all 0.35s;
      font-size: 20px;
      font-weight: 500;      

      &:hover {
        background-color: $primary-green-niq;
        border-color: $primary-green-niq;
      }
    }
  }
}

.webshoppers-43 {
  background-color: black;
  color: white;
  //border-radius: .8rem;
  padding: 30px;
  margin: 50px auto;

  .img-footer-ws-43 {
    @media (max-width: 768px) {
      display: none;
    }
  }

  & h2 {
    text-transform: uppercase;
    color: white;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    border-color: $color-primary-red-nielsen;

    @media (max-width: 600px) {
      font-size: 30px;
    }
  }
}

// Banner Página Websshoppers-Custom
.banner-webshoppers-custom {
  background: url("../../assets/imagens/BG-test.PNG");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  object-fit: cover;

  &__text-bn-ws-pr-43 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    color: white;
    padding-top: 67px;

    .descripition-ws-custom-43 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-bottom: 1px solid;
      width: 100%;
      margin-top: -25px;

      h1 {
        font-size: 50px;
        font-family: $font-family-secondary;

        @media (max-width: 421px) {
          font-size: 40px;
        }
      }
    }

    .vs-title {
      font-size: 25px;
      color: #07b9ff;
    }
  }

  img {
    width: 180px;
    margin-top: -18px;
    margin-right: -28px;
  }
}

.content-ws-custom-43 {
  display: flex;
  flex-direction: column;
  //align-items: center;
  margin: 80px auto 10px auto;

  .report-info {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    h2 {
      margin-top: 50px;
      color: #ffc107;
    }

    img {
      width: 250px;
      //margin-top: 50px;
      margin-right: 20px;
    }
  }

  .acc-test {
    margin-bottom: 50px;
  }

  .btn-toggle {
    text-decoration: none;
    padding: 10px 20px;
    width: 100%;
    text-align: start;
    margin-bottom: 30px;
    transition: all 0.5s;
    color: $shades-gray-80;
    font-family: $font-family-secondary;

    &:hover {
      //border: 1px solid;
      //border-color: #ffc1079c;
      background-color: black;
      color: #07c1ff;
      //text-align: center;
    }

    &:focus {
      box-shadow: none;
      border: 1px solid;
      border-color: #07c1ff;
      //text-align: end;
    }

    h3 {
      border-bottom: 1px solid;
      border-color: $color-secondary-gray-nielsen;
      font-size: 25px;
    }
  }

  .pricing-custom {
    font-size: 20px;
    margin-left: -20px;
    text-align: end;
    font-family: $font-family-secondary;

    span {
      font-size: 15px;
    }
  }

  .report-itens {
    display: flex;
    flex-wrap: wrap;
    justify-items: flex-start;

    .descripition-itens {
      display: flex;
      flex-direction: column;
      width: 330px;
      margin: 17px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      border-color: $color-secondary-gray-nielsen;
      padding: 7px;
      -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      box-shadow: 5px 7px 7px 0px rgba(214, 214, 214, 1);
      //background-color: black;
      //color: white;

      h4 {
        font-size: 20px;
        font-weight: 500;
        color: $shades-gray-60;
        font-family: $font-family-secondary;
        //color: #ffc107;
        //background-color: black;
      }

      p {
        font-size: 17px;
      }
    }
  }

  .btn-purchase {
    text-align: center;
    margin: 30px auto 55px auto;

    a {
      color: black;
      font-size: 25px;
      border: 1px solid;
      text-decoration: none;
      padding: 7px 35px;
      transition: all 0.35s;
      font-size: 27px;
      font-weight: 500;

      &:hover {
        background-color: $primary-green-niq;
        border-color: $primary-green-niq;
      }
    }
  }
}

.contato-comercial {
  font-family: $font-family-secondary;

  h5 {
    text-align: center;
    @media (max-width: 600px) {
      padding: 5px;
      text-align: initial;
    }
  }
}

.sem-quebra {
  white-space: nowrap;
}


