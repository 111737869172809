@import '../../tokens/tokens.scss';




.shopper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $text-size-primary;

  &__improve-medal {
    //width: 60%;
    padding: 10px;
    //border-right: 1px solid;
    //border-color: $color-secondary-gray-nielsen;
    margin: 10px;

    & h3 {
      text-transform: uppercase;
      color: $color-primary-red-nielsen;

      @media(max-width: 600px) {
        font-size: 30px;
      }
    }

    & h5 {
      text-transform: uppercase;
      color: $color-primary-black-nielsen;
    }

    & ul {
      margin-left: 35px;
    }

    & a {
      text-decoration: none;
    }


  }

  &__reputation-stores {
    //width: 40%;
    margin: 10px;


    & h3 {
      text-transform: uppercase;
      color: $color-primary-red-nielsen;

      @media(max-width: 600px) {
        font-size: 30px;
      }
    }

    & img {
      width: 100%;
      height: 300px;
      object-fit: cover;

      @media(max-width: 430px) {
        width: 100%;
      }
    }

  }

}


.portal-access {
  margin-top: 50px;
  font-size: $text-size-primary;

  &>h2 {
    text-transform: uppercase;
    color: $color-primary-red-nielsen;
    margin-bottom: 30px;

    @media(max-width: 600px) {
      font-size: 30px;
    }

  }

  &__style {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: black;
    min-height: 300px;
    border-radius: .3rem;
    padding: 10px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 30px;

    &__step-access {
      width: 250px;
      height: 250px;
      margin-top: 50px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;


      &__imgs-portal {
        width: 170px;
        height: 115px;
        border-bottom: 6px solid;
        border-color: white;
        margin-bottom: 15px;

        &>div {
          width: 170px;
          height: 100px;
          border: 6px solid;
          border-color: white;
          margin-bottom: 10px;
          background-color: $color-primary-gray-nielsen;

          & img {
            height: 98%;
            max-width: 90%;
          }
        }

      }

      & p {
        color: white;
      }
    }


  }

  &__infos {
    & ul {
      margin-left: 35px;
    }
  }
}



.content-portal {
  margin-top: 100px;
  margin-bottom: 100px;
  font-size: $text-size-primary;

  &>h2 {
    text-transform: uppercase;
    color: $color-primary-red-nielsen;
    margin-bottom: 30px;

    @media(max-width: 600px) {
      font-size: 30px;
    }

  }

  &__style {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__bg-img {
      width: 350px;
      //height: 400px;

      &>img {
        width: 100%;
        height: 357px;
        object-fit: cover;
      }
    }

    &__infos {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px auto;
      width: 690px;

      & .text-infos {
        padding: 0 10px;
      }

      & .points-infos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        //max-width: 700px;

        &__div-main {
          width: 190px;
          margin-right: 40px;

          & img {
            width: 100%;
          }
        }
      }
    }
  }

  &__release-steps {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & .style1 {
      display: flex;
      margin: 30px auto;
      flex-wrap: wrap;
      width: 400px;

      &>img {
        width: 200px;
        margin-right: 15px;
      }

      & div {
        width: 380px;

        @media(max-width:380px) {
          width: 280px;
        }

        & p {

          // width: 360px;
          & strong {
            color: $color-primary-red-nielsen;
          }
        }
      }
    }
  }

  &__table-params {
    margin: 30px auto;
    // border: 1px solid;
    // border-color: $color-secondary-gray-nielsen;
    // border-radius: .8rem;
    font-size: medium;

    @media(max-width:460px) {
      display: none;
    }

    & table {
      //width: 100%;
      //height: 100px;

      & tr {
        height: 10px;

        & th {
          font-size: 15px;
        }

        & .td1 {
          text-align: end;
          font-style: italic;
          text-transform: uppercase;
        }

        & .td2 {
          text-align: center;
          font-style: normal !important;
        }

        & .name-color {
          background-color: $color-primary-gray-nielsen;
          color: white !important;
          text-align: center;
        }

      }
    }
  }
}

.score {

  margin-bottom: 100px;
  font-size: $text-size-primary;

  &>h2 {
    text-transform: uppercase;
    color: $color-primary-red-nielsen;
    margin-bottom: 30px;

    @media(max-width: 600px) {
      font-size: 30px;
    }
  }

  &__points {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    //margin: 20px;
    margin: 70px auto;

    &__infos {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-color: $color-primary-red-nielsen;
      border-radius: 1.5rem 1.5rem 1.5rem 0;
      max-width: 330px;
      height: 130px;
      margin: 20px;
      -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);

      & .number {
        background-color: black;
        height: 100%;
        border-radius: 1.5rem 0 0 0;
        border-right: 3px solid;
        border-right-color: $color-primary-red-nielsen;
        display: flex;


        &>p {
          color: white;
          font-size: 40px;
          margin-top: 8px;
          padding: 5px;
        }
      }

      & .text2-info {
        margin: 11px;
      }
    }
  }

}

.bg-score {
  //background: url('../../assets/imagens/img-news2333.jpg');
  background-color: black;
  background-size: cover;
  background-attachment: fixed;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: $text-size-primary;

  & .score-medal {
    display: flex;
    flex-direction: column;
    margin: 20px;

    &__number2 {
      background-color: $color-primary-red-nielsen;
      color: white;
      text-align: center;
      width: 100px;
      height: 30px;
      border-radius: 1.5rem 1.5rem 0 0;


      & p {
        font-size: larger;
      }

    }

    &__text-info3 {
      background-color: black;
      border: 1px solid;
      border-color: $color-primary-red-nielsen;
      width: 350px;
      height: 140px;
      padding: 10px;

      @media(max-width:460px) {
        width: 280px;
        min-height: 100px;
      }

      & p {
        color: white;
      }
    }
  }

  & .img01-medal {
    width: 100px;

    & img {
      width: 100%;
    }
  }
}

.score-infos {
  margin-top: 70px;
  margin-bottom: 70px;
  font-size: $text-size-primary;

  &__steps-2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & .steps-2-infos {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid;
      border-color: $color-secondary-red-nielsen;
      border-radius: 1.5rem 1.5rem 1.5rem 0;
      width: 310px;
      height: 130px;
      margin: 20px;
      padding: 13px 10px;
      -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
      box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);



      & p {
        font-size: $text-size-primary;
        margin-bottom: 0;
      }
    }

  }


  &__text-img {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;

    & .text4 {
      width: 60%;

      @media(max-width: 999px) {
        width: 100%;
      }

      &>p {
        &>b {
          color: $color-primary-red-nielsen;
        }
      }

      & ul {
        margin-left: 35px;
      }
    }
  }

  & .img4 {
    width: 40%;

    @media(max-width: 1000px) {
      display: none;
    }

    & img {
      width: 100%;
    }
  }
}

.solutions-ebit {
  background-color: black;
  color: white;
  border-radius: .8rem;
  padding: 30px;
  margin: 50px auto;

  & h2 {
    text-transform: uppercase;
    color: white;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    border-color: $color-primary-red-nielsen;

    @media(max-width: 600px) {
      font-size: 30px;
    }
  }

  &__map-text {

    & .style2 {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: center;


      & img {
        width: 250px;
        padding: 20px;
        margin-left: 20px;
      }
    }

  }
}

.webshoppers {
  background-color: black;
  color: white;
  border-radius: .8rem;
  padding: 30px;
  margin: 50px auto;

  & h2 {
    text-transform: uppercase;
    color: white;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    border-color: $color-primary-red-nielsen;

    @media(max-width: 600px) {
      font-size: 30px;
    }
  }

  &__imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;


    & div {
      width: 320px;
      margin: 10px;

      & img {
        width: 100%;
        border-radius: .3rem;
      }
    }

  }
}

.special-study {
  background-color: black;
  color: white;
  border-radius: .8rem;
  padding: 30px;
  margin: 50px auto;

  & h2 {
    text-transform: uppercase;
    color: white;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    border-color: $color-primary-red-nielsen;

    @media(max-width: 600px) {
      font-size: 30px;
    }
  }

  &>div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & img{
      width: 100px;
    }

  }
}

.termos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 70px auto;
  font-size: $text-size-primary;

  & .termo-cadastro {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: $color-secondary-gray-nielsen;
    border-radius: .3rem;
    padding: 10px;
    margin: 20px;
    width: 570px;

    & img {
      width: 150px;
      margin-right: 20px;
    }
  }
}

.business-contact {
  font-size: $text-size-primary;
  margin-bottom: 80px;

  & h2 {
    text-transform: uppercase;
    color: $color-primary-red-nielsen;
    margin-bottom: 30px;

    @media(max-width: 600px) {
      font-size: 30px;
    }
  }
}