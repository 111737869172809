
//Colors Nielsen -Primary- 2020/07/15
$color-primary-blue-nielsen: #00aeef;
$color-primary-white-nielsen: #ffffff;
$color-primary-gray-nielsen: #8b959b;
$color-primary-black-nielsen: #000000;
$color-primary-gold-nielsen: #ffb100;
$color-primary-purple-nielsen: #b21dac;
$color-primary-green-nielsen: #8dc63f;
$color-primary-red-nielsen: #dd0014;

//Colors Nielsen -Secondary- 2020/07/15
$color-secondary-blue-nielsen: #6bd6f7;
$color-secondary-gray-nielsen: #e4e8eb;
$color-secondary-gold-nielsen: #ffd147;
$color-secondary-purple-nielsen: #e06ee5;
$color-secondary-green-nielsen: #c4df9b;
$color-secondary-red-nielsen: #fa4f4f;

//Colors Nielsen -Tertiary- 2020/07/15
$color-tertiary-blue-nielsen: #baeaf9;
$color-tertiary-gray-nielsen: #f8f9fa;
$color-tertiary-gold-nielsen: #ffe5ad;
$color-tertiary-purple-nielsen: #f7bff2;
$color-tertiary-green-nielsen: #e0eecb;
$color-tertiary-red-nielsen: #ffc9cc;

$bg-primary: #e9ecef87;
$primary-text-color: #495057;

$title-primary: 40px;
$title-secondary: 30px;
$text-size-primary: 18px;
$font-family-primary: Poppins;

$font-family-secondary: Montserrat;


// Palette colors NielsenIQ

// -- Primary Palette -- 
$primary-green-niq: #00f000;
$primary-black-niq: #000000;
$primary-white-niq: #ffffff;


// -- Secondary Palette --
$light-green-niq: #BDFFBB;
$lighter-green-niq: #62F662;
$darker-green-niq: #00CA23;
$darkest-green-niq: #00A346;

$cell-colors-hover-niq: #D6FEFF;
$selected-row-niq: #B9F9FF;

$icon-accent-niq: #B21DAC;
$purple-lt-niq: #EBE3FF;
$badge-accent-niq: #682EE0;
$unique-alert-error-niq: #FDD5E1;
$error-dark-niq: #FB087A;
$error-xTraDark-tertiary-niq: #E7046F;


// -- Palette Grays --
$shades-gray-5: #F2F2F2;
$shades-gray-15: #D9D9D9;
$shades-gray-30: #B3B3B3;
$shades-gray50: #808080;
$shades-gray-60: #666666;
$shades-gray-80: #333333;
$shades-gray-90: #1A1A1A;
$shades-gray-95: #0D0D0D;


//-- Reporting Palette
$reporting-blue: #006699;
$reporting-aqua: #44AAAA;
$reporting-green: #447733;
$reporting-gold: #BBAA44;
$reporting-orange: #CC7711;
$reporting-red: #993344;
$reporting-pink: #AA55AA;
$reporting-purple: #552288;
$reporting-dark-grey: #777777;
$reporting-light-blue: #7799BB;
$reporting-light-green: #88AA77;
$reporting-light-grey: #999999;
$reporting-light-yellow: #EEDD55;
$reporting-light-red: #CC7788;
$reporting-light-orange: #DDAA77;
$reporting-light-pink: #DDCCDD;
$reporting-threshold-red: #DD0014;
$reporting-threshold-green: #26B910;






