@import '../../../tokens/tokens.scss';

.company-infos {
  background-color: white;
  border: 1px solid;
  border-color: $color-secondary-gray-nielsen;
  //border-radius: .8rem;
  padding: 10px;
  max-width: 265px;
  -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
  box-shadow: -2px 4px 7px 0px #d6d6d6;

  @media (max-width: 1310px) {
    max-width: 90%;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
  }

  &>p {
    font-size: 20px;
  }


  &__description {

    &>p {
      font-size: 16px;
      // color: $color-primary-gray-nielsen;
    }
  }

  & .social-media {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
  }
}