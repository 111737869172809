@import '../../tokens/tokens.scss';

.stepSurvey {
    margin: 0px auto;
    padding: 50px;

}

.aboutUs {
    display: flex;
    flex-direction: column;

    &>h3 {
        font-size: $title-primary;
        font-family: $font-family-primary;
        font-weight: 500;
        color: $color-primary-red-nielsen;
        margin-bottom: 30px;
        margin-top: 50px;
        text-align: left;
        text-transform: uppercase;
    }

    &>p {
        font-size: 21px;
        font-weight: 100;
        line-height: 2.1rem;
        margin-bottom: 50px;
    }
}

.items-stepSurvey {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__box-stepSurvey {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: white;
        border: 2px solid;
        border-radius: .4rem;
        border-color: $color-secondary-gray-nielsen;
        padding: 10px 15px;
        max-width: 246px;
        margin: 15px;
        min-height: 370px;
        max-height: 370px;
        box-shadow: -2px 4px 7px 0px #d6d6d6;

        &:hover {
            border-color: $color-secondary-blue-nielsen;
        }



        & .img-stepSurvey {
            max-width: 120px;
            margin: 27px 0;

            &.img-stepSurvey01 {
                margin-top: -26px;
            }

            &.img-stepSurvey02 {
                margin-top: 0px;
            }

            &.img-stepSurvey03 {
                margin-top: 33px;
            }

            &.img-stepSurvey04 {
                margin-top: 10px;
            }

            &>img {
                width: 100%;
            }
        }

        &>p {
            text-align: left;
            color: $color-primary-gray-nielsen;
        }
    }
}