@import '../../tokens/tokens.scss';


.aboutUs-medal-pag {
  position: relative;
  // background: green;

  &>h3 {
    font-size: $title-primary;
    font-family: $font-family-primary;
    font-weight: 500;
    color: $color-primary-red-nielsen;
    margin-bottom: 30px;
    text-align: left;
    padding-top: 50px;
    text-transform: uppercase;
  }

  &__newMedals-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    flex-wrap: wrap;
    

    &>div {
      width: 110px;
      margin: 30px 20px;
      border: 1px solid;
      border-radius: .3rem;
      border-color: $color-tertiary-gray-nielsen;
      padding: 10px;
      box-shadow: -2px 4px 7px 0px #d6d6d6;
      transition: all .1s;
      min-width: 110px;
      cursor: pointer;

      & img {
        width: 100%;
        border-radius: 0.3rem;
      }
    }
  }
}

.infos-medals-true {
  font-size: 18px;

}


.img-toggle-medal {
  bottom: -201px;
  left: 10px;
  position: absolute;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    border-radius: 10px !important;
  }
}

.img-toggle-medal {

  &.new-bronze{
    border: 3px double;
    border-color: #c5611b;
    box-shadow: -2px 4px 7px 0px #d6d6d6;
  }

  &.new-prata{
    border: 3px double;
    border-color: #8b9aa6;
    box-shadow: -2px 4px 7px 0px #d6d6d6;
  }

  &.new-ouro{
    border: 3px double;
    border-color: #ffb100;
    box-shadow: -2px 4px 7px 0px #d6d6d6;
  }

  &.new-diamante{
    border: 3px double;
    border-color: #a2dbea;
    box-shadow: -2px 4px 7px 0px #d6d6d6;
  }




  &.avaliacao{
    bottom: -165px;
    left: 62px;
    position: absolute;
    width: 92%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px double;
    border-color: #00aeef;
    box-shadow: -2px 4px 7px 0px #d6d6d6;
  
    & img {
      width: 100%;
    }
  }

  &.inactiveMedal{
    bottom: -144px;
    left: 431px;
    position: absolute;
    width: 54%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px double;
    border-color: #dc0015;
    box-shadow: -2px 4px 7px 0px #d6d6d6;
  
    & img {
      width: 100%;
    }
  }
}

.img-banner-secondary-medal {
  margin: 50px auto 80px auto;

  & img {

    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

