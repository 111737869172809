@import '../../tokens/tokens.scss';


// .footerEbit{
//     padding-top: 40px;
//     padding-bottom: 40px;

// }


// .logo-footer{
//     width: 120px;
// }

// .icon-social-media{
//     font-size: 40px;
//     color: white;
// }

// .h6-color{
//     color: white;
// }

// .text-reset{
//     font-size: 15px;
//     margin-bottom: 0 !important;
//     color: #d4dae1 !important;
// }

.footer-eb-mx {
    background: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: $font-family-secondary;
    justify-content: center;
    min-height: 240px;
    color: #fff;
    column-gap: 30px;
  
    & .div-ft-links {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 45px;
      margin-top: 20px;
  
      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;
      }
  
      &__imgLogo {
        & img {
          width: 130px;
        }
      }
  
      &__contacts {
        display: flex;
  
        @media (max-width: 991px) {
          flex-direction: column;
          margin-top: 20px;
          margin-bottom: -20px;
        }
  
        & .div-contactUs {
          margin-right: 30px;
          
  
          & a {
            color: #fff;
            margin-left: 30px;
            border-right: 0.5px solid #ffffff73;
            padding-right: 30px;
            font-size: 18px;
            text-decoration: none;
          }
  
          & .cont-us-a1 {
  
            @media (max-width: 991px) {
              margin-left: 0px;
              padding-right: 18px;
              font-size: 15px;
            }
          }
          & .cont-us-a2 {
  
            @media (max-width: 991px) {
              margin-left: 18px;
              border-right: none;
              font-size: 15px;
            }
          }
        }
  
        & .social-media {
          align-items: center;
          display: flex;
          justify-content: flex-end;
          max-width: 214px;
          gap: 30px;
  
          @media (max-width: 991px) {
            margin-top: 20px;
          }
  
          & a {
            font-size: 20px;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            padding-bottom: 2px;
            border-bottom: 2px solid transparent;
            transition: all .22s;
  
            &:hover{
              border-bottom: 2px solid #00f000;
            }
          }
        }
      }
    }
  
    & .div-ft-terms {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
  
      & .div-polcy-terms {
        @media (max-width: 991px) {
          margin-bottom: 15px;
          display: flex;
        }
  
        a {
          color: #fff;
          border-right: 0.5px solid #ffffff73;
          padding-right: 30px;
          font-size: 15px;
          text-decoration: none;
          position: relative;
          transition: all 0.40s;
  
          &::after {
            content: "";
            width: 90%;         
            //height: 4px;
            border-bottom: 2px solid #00f000;
            position: absolute;
            bottom: 0;
            left: 0;
            display: none;
            transition: all 0.40s;
          }
  
          @media (max-width: 991px) {
            font-size: 12px;
            padding-right: 18px;
          }
  
          &:hover::after{
            display: inline-block;
          }
        }
        & .rights-reserved{
          & small{
            @media(max-width: 991px){
            font-size: 8px;
          }
          }
          
        }
      }
    }
  }
  