@import '../../tokens/tokens.scss';

.about-ebit-text {
    font-size: $text-size-primary;
    margin: 70px auto;
  
    & h2 {
      font-size: $title-primary;
      font-family: $font-family-primary;
      color: $color-primary-red-nielsen;
      text-align: left;
      padding-top: 50px;
      margin-bottom: 30px;
      text-transform: uppercase;

      @media(max-width: 600px){
        font-size: 30px;
      }
    }
  
    & div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 20px;
      margin-left: -95px;
  
      & img {
        width: 300px;
        margin: 20px;

        // @media(max-width: 1199px){
        //     width: 500px;
        // }

        
      }

      & .img-responsive{

        @media(max-width: 1199px){
            display: none;
           // width: 100%;
        }
      }

  
      & p {
        width: 650px;
        margin: 20px;
  
      }
    }
  }





.ebit-numeros {
    background-color: black;
    min-height: 500px;
    color: $color-primary-white-nielsen;
    text-align: center;
}


.title-ebit-numeros>p {
    padding-top: 15px;
    font-size: $title-primary;
    color: $color-primary-red-nielsen;
    text-align: left;
    text-transform: uppercase;

    @media(max-width: 600px){
        font-size: 30px;
      }

}

.info-ebit-numeros {
    text-align: left;
}

.display-ebit-numeros {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;


    &__items-ebit-numero {
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        padding: 10px;
        width: 240px;
        height: 320px;
        border: 1px;
        border-style: dashed;
        border-color: $color-primary-blue-nielsen;
        border-radius: .5rem;
        margin: 5px 5px 30px 5px;

        & img {
            width: 85px;
        }


    }
}


.numero-ebit {
    font-size: 40px;
    font-weight: 700;
}

.info-numeros-ebit {
    font-weight: 100;
    font-size: 20px;
    
}


.time-line-ebit {
    background-color: black;
    min-height: 500px;

    & div {

        & h2 {
            font-size: $title-primary;
            font-family: $font-family-primary;
            color: $color-primary-white-nielsen;
            text-align: left;
            padding-top: 50px;
            margin-bottom: 50px;
            text-transform: uppercase;

            @media(max-width: 600px){
                font-size: 30px;
                text-align: center;
              }
        }
        & .lt-responsive{
            & img {
                width: 100%;  
                @media(max-width: 890px){
                    display: none;
                }
            }            
            
        }

        & .lt-responsive01{
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
                width: 100%; 

                @media(min-width: 890px){
                    display: none;
                }

                @media(max-width: 889px){
                    display: block;
                }
            }            
            
        }

        

       
    }
}

