@import '../../../tokens/tokens.scss';


.banner-company {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $color-secondary-gray-nielsen;

    @media (max-width: 700px) {
        flex-direction: column;
    }

    &__logo-company {
        width: 150px;
        height: 150px;
        background-color: white;
        border-radius: 50%;
        margin-top: 1rem;
        border: 1px double;
        border-color: $color-primary-gray-nielsen;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
        -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
        box-shadow: -2px 4px 7px 0px #d6d6d6;

        &>img {
            width: 95px;
        }
    }

    &__name-company {
        margin-left: 200px;

        @media (max-width: 700px) {
            margin-left: 0;
        }

        &>span {
            font-size: 3rem;
        }
    }

}