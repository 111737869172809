
@import "../tokens/tokens.scss";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

