@import '../../tokens/tokens.scss';


.aboutUs-medal {

    &>h3 {
        font-size: $title-primary;
        font-family: $font-family-primary;
        font-weight: 500;
        color: $color-primary-red-nielsen;
        margin-bottom: 30px;
        text-align: left;
        padding-top: 50px;
        text-transform: uppercase;
    }

    &__newMedals {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 100px;
        flex-wrap: wrap;

        &>div {
            width: 110px;
            margin: 30px 20px;
            border: 1px solid;
            border-radius: .3rem;
            border-color: $color-tertiary-gray-nielsen;
            padding: 10px;
            box-shadow: -2px 4px 7px 0px #d6d6d6;
            transition: all .1s;
            min-width: 110px;

            // &:hover {
            //     border-color: $color-primary-blue-nielsen;
            //     width: 150px;
            //     margin: -40px 0px;
            // }

            & img {
                width: 100%;
                border-radius: .3rem;
            }
        }
    }

    &__button-newMedals {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -50px auto 50px auto;

        &>a {
            text-decoration: none;
            border: 1px solid;
            border-radius: 2.5rem !important;
            border-color: $color-tertiary-gray-nielsen;
            padding: 10px 25px !important;
            box-shadow: -2px 4px 7px 0px #d6d6d6;
            color: $color-primary-gray-nielsen;
            transition: all 0.2s;

            background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA 51%, #1FA2FF 100%);
            margin: 10px;
            padding: 15px 45px;
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;

            &:hover {
                background-position: right center;
                color: #fff;
                text-decoration: none;
            }

            & span{
                text-decoration: underline;
                font-weight: 700;
            }
        }


    }
}