@import '../../tokens/tokens.scss';


.companies {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.banner-NIQ-Reputation {
    background: url("../../assets/imagens/BannerCompanyReputation.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: 430px;
    margin-top: -75px;
    display: flex;
    align-items: center;
    //filter: opacity(10);
  
    & div {
      display: flex;
      flex-direction: column;
      color: white;
  
      & h2 {
        font-size: 40px;
        font-family: Montserrat !important;
        font-weight: 700;
        margin-top: 70px;
      }
  
      & h3 {
        font-family: Montserrat !important;
        font-size: 30px;
        font-weight: 700;
      }
    }
  }