@import "../../tokens/tokens.scss";

.banner-home-NIQ {
  background: url("../../assets/imagens/BannerRegulamentoPremio.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 430px;
  margin-top: -75px;
  display: flex;
  align-items: center;

  & div {
    display: flex;
    flex-direction: column;
    color: white;

    & h2 {
      font-size: 40px;
      font-family: Montserrat !important;
      font-weight: 700;
      margin-top: 70px;
    }

    & h3 {
      font-family: Montserrat !important;
      font-size: 30px;
      font-weight: 700;
    }
  }
}

.div-sub-color{
  background-color: #0000009c;
  height: 550px;
  margin-top: -550px;
  margin-bottom: 55px;

}

.personas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  

  & a {
    text-decoration: none;
    
    transition: .3s;
    &:hover{
      
      & .btn_seta {  
        & img {
            background-color: #00f000!important;
            border-color: #00f000;          
        }
      }
    }
  }

  &__div-link-personas {
    & > div {
      min-width: 300px;
      min-height: 230px;
      max-width: 300px;
      max-height: 230px;
      margin: 15px 15px 35px 0px;
      padding: 25px 15px 15px 15px;
      font-family: Montserrat;
      text-decoration: none;
      color: black;
      transition: all 0.35s;
      border: 1px solid #b3b3b3;
      transition: all 0.32s;
    }

    &__card {
      display: flex;
      flex-direction: column;
      //align-items: flex-start;
      transition: all 0.32s;
      justify-content: space-around;

      & h4 {
        font-size: 20px;
        text-align: start;
        font-weight: 800;
        margin-top: 0px;

        &:hover {
          text-decoration: underline;
        }
      }

      &__title-home {
        font-size: 20px;
        font-weight: 800;
      }

      & p {
        text-align: start;
        line-height: 1.3rem !important;
        font-size: 14.2px;
        color: #667;
      }

      & div {
        & img {
          width: 80px;
        }
      }

      & .btn_seta {
        text-align: end;
        transition: all 0.32s;

        & img {
          padding: 6px;
          width: 36px;
          border: 1px solid;
          text-align: end;
          transition: all 0.32s;

          // &:hover{
          //   background-color: #00f000!important;
          //   border-color: #00f000;
          // }
        }
      }

      &__sub-title {
        color: $color-primary-black-nielsen;
        font-size: 18px;
        margin-bottom: 15px;
      }
    }
  }
}
