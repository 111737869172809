@import "../../tokens/tokens.scss";

.menu-nielsen {
  background: rgba(255, 255, 255, 0) !important;
  height: 75px;
  border-bottom: 0.5px solid #e5e5e5;
  color: #e5e5e5 !important;
  z-index: 11;

  & a{
    color: #cecccc !important;

    & :hover{
      color: white !important;
    }
  }

  & .logo-ebit > img {
    width: 105px;
    margin-right: 30px;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    //background: $color-primary-white-nielsen;

    &__item {
      margin-left: 30px;
      //background: $color-primary-white-nielsen;
    }
  }
}
