@import "../../tokens/tokens.scss";

.banner-premio2021 {
  background: url("../../assets/imagens/BannerRegulamentoPremio.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 430px;
  margin-top: -75px;
  display: flex;
  align-items: center;

}

.bg-text-premio2021 {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

// .bg-text-premio2021-div1 {
//   display: flex;
//   flex-direction: column;
// }

.bg-text-premio2021-div2 {
 
}

.bg-text-premio2021 img {
  width: 320px;
  /* margin-top: 30px; */
  opacity: 0.8;
  margin-left: -56px;
}

.bg-text-premio2021 h1 {
  font-size: 50px;
  font-family: Montserrat !important;
  font-weight: 600;
  margin-top: 70px;

  
}

.bg-text-premio2021 h3 {
  font-family: Montserrat !important;
  font-size: 30px;
  font-weight: 700;
}

@media (max-width: 752px) {
  .bg-text-premio2021 {
    align-items: center;
    justify-content: center;
    font-size: 40px;
  }

  .bg-text-premio2021-div1 {
    align-items: center;
    justify-content: center;
    margin-left: 0px;
  }

  .bg-text-premio2021-div2 {
    align-items: center;
    justify-content: center;
  }

  .bg-text-premio2021 img {
    width: 220px;
    margin-top: 80px;
    opacity: 0.8;
    margin-left: 0px;
  }
}

@media (max-width: 550px) {
  .banner-premio2021 {
    height: 480px;
  }

  .bg-text-premio2021 img {
    width: 180px;
  }

  .bg-text-premio2021 h1 {
    font-size: 35px;
  }

  .bg-text-premio2021 h3 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .bg-text-premio2021 img {
    width: 120px;
  }
}

@media (max-width: 410px) {
  .bg-text-premio2021 h1 {
    font-size: 35px;
  }
}

@media (max-width: 368px) {
  .bg-text-premio2021 img {
    width: 120px;
  }

  .bg-text-premio2021 h1 {
    font-size: 28px;
  }
}

// .banner-buttom{
//   width: 100%;
//   background-color: white;
//   margin-top: -2px;
//   margin-bottom: -150px;
// }

.banner-premio-ebit {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 500px) {
    margin-bottom: -150px;
  }

  &__img-premio1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
      height: 400px;
      width: 100%;
      object-fit: cover;

      @media (max-width: 500px) {
        display: none;
      }
    }

    & div {
      margin-top: -400px;

      & img {
        width: 850px;
        height: 400px;
        object-fit: cover;

        @media (max-width: 850px) {
          width: 500px;
        }

        @media (max-width: 500px) {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: initial;
          margin-top: 100px;
          margin-bottom: -200px;
        }
      }
    }
  }
}

.pag-awards {
  margin-top: 50px;
  font-size: $text-size-primary;

  & > h2 {
    // text-transform: uppercase;
    color: $color-primary-red-nielsen;
    margin-bottom: 30px;

    @media (max-width: 600px) {
      font-size: 30px;
    }
  }
}

.banner-secondario {
  background-color: #840022;
  min-height: 250px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 80px auto;
  color: white;

  & img {
    width: 250px;
    margin-right: 20px;
    margin-bottom: 15px;
  }

  & p {
    font-size: 25px;

    & a {
      text-decoration: none;
      color: white;
    }
  }
}

.banner-secondario02 {
  background-color: #840022;
  min-height: 250px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0px auto 80px auto;
  color: white;
  padding: 20px;

  & img {
    width: 160px;
    margin-left: 20px;
    margin-bottom: 15px;

    @media (max-width: 600px) {
      width: 130px;
    }
  }

  & p {
    font-size: 28px;
    text-transform: uppercase;

    @media (max-width: 600px) {
      font-size: 20px;
    }

    & a {
      text-decoration: none;
      color: white;

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
  }
}

.cat-awards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  & .itens-cat-awards {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 300px;
    margin: 30px;

    &.di-top-5 {
      & img {
        width: 90px;
      }
    }

    & img {
      width: 60px;
    }

    & div {
      margin-left: 10px;

      & h5 {
        font-size: 18px;
        // margin-left: 10px;
        font-style: unset;
      }
    }
  }
}

.cat-10-voting {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & div {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 300px;
    margin: 30px;
  }

  & h5 {
    font-size: 18px;
    margin-left: 10px;
    font-style: unset;
  }

  & img {
    width: 60px;
  }
}

.title-cat-awards {
  margin-top: 80px;
  text-transform: uppercase;
  color: $color-primary-red-nielsen;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    font-size: 30px;
  }
}

.title-curiosidade {
  margin-top: 80px;
  text-transform: uppercase;
  color: $color-primary-red-nielsen;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    font-size: 30px;
  }
}

.curiosidades {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}

.regulamento-concorra {
  margin-bottom: 80px;
  font-family: Montserrat !important;
  margin-top: 50px;
  

  & .title_regulation{
    font-size: 25px;    
  } 
}

.ul-reg-pr {
  margin: 0;
  padding: 20px;
  font-size: 16px;
  line-height: 30px;
}

.award-winners {
  margin-top: 60px;
  margin-bottom: 80px;
  font-family: $font-family-secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-secondary-red-nielsen;

  h2 {
    text-align: initial;
    color: $reporting-threshold-red;
    margin-bottom: 50px;
    font-weight: 600;
  }

  h3 {
    font-weight: 600;
  }

  & .winners-class {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    p {
      font-weight: 500;
      color: #dc0015;
    }
  }

  & .bloco-stores-winners {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;

    @media (max-width: 1200px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__card-award {
    width: 250px;
    max-height: 150px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px double $shades-gray-15;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 7px 7px 0 #d6d6d6;
    transition: all 0.32s;
    margin-bottom: 20px;
    text-decoration: none;

    &:hover {
      border-color: $color-secondary-gray-nielsen;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
      text-decoration: none;
    }

    & .card-store-winner {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 130px;
        max-height: 100px;
        min-width: 130px;
        min-height: 100px;
      }

      img {
        width: 100%;
      }

      p {
        color: $shades-gray-80;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.ebit-award-video {
  width: 865px;
  height: 490px;
  margin-left: auto;
  margin-right: auto;

  iframe {
    width: 100%;
    height: 100%;
  }
}
