@import '../../tokens/tokens.scss';

.reputation-details {

  display: grid;
  grid-gap: 8px;
  grid-template-columns: 30% 43% 27%;
  grid-template-areas: "headerCompany headerCompany headerCompany"
    "reputation commentaries social";
  // grid-template-areas: "header" "reputation" "commentaries" "social";


  @media (max-width: 1310px) {
    grid-template-columns: 30% 43% 27%;
    grid-template-areas:
      "headerCompany headerCompany headerCompany"
      "reputation reputation reputation"
      "social social social"
      "commentaries commentaries commentaries";
  }

  background-color:$bg-primary;



  &__header {
    grid-area: headerCompany;
    margin-bottom: 15px;
  }

  &__box-reputation {
    grid-area: reputation;
    margin-left: 100px;

    @media (max-width: 1020px) {
      margin-left: 55px;
    }

    @media (max-width: 500px) {
      margin-left: 30px;
    }
  }

  &__commentaries {
    grid-area: commentaries;
    margin-top: -8px;

    @media (max-width: 1310px) {
      margin-left: 100px;
    }

    @media (max-width: 1020px) {
      margin-left: 55px;
    }

    @media (max-width: 500px) {
      margin-left: 20px;
    }
  }

  &__social {
    grid-area: social;

    @media (max-width: 1310px) {
      margin-left: 100px;
    }

    @media (max-width: 1020px) {
      margin-left: 55px;
    }

    @media (max-width: 500px) {
      margin-left: 30px;
    }
  }
}

.pag-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 110%;
  background-color: white;
  min-width: 350px;
  border: 1px solid;
  border-color: $color-secondary-gray-nielsen;
  border-radius: .8rem;
  -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
  box-shadow: -2px 4px 7px 0px #d6d6d6;

  padding: 40px;

  @media(max-width: 900px){
    margin: 90px 100%;
  }

  @media(max-width: 650px){
    margin: 90px 60%;
  }

  @media(max-width: 470px){
    margin: 90px 10%;
  }

  @media(max-width: 370px){
    margin: 90px 10%;
    padding: 20px;
    min-width: 300px;
  }


}