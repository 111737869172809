@import "../../tokens/tokens.scss";

.img-carousel {
  background-color: #000000;
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.img-award2021 {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 220px;

    @media (max-width: 1056px) {
      width: 180px;
    }

    @media (max-width: 704px) {
      width: 130px;
    }
  }
}

.text-banner-h2 {
  font-size: 18px;
  font-family: $font-family-secondary;
  line-height: 1.6;

  @media (max-width: 370px) {
    font-size: 15px;
  }

  a {
    white-space: nowrap;
    font-family: $font-family-secondary;
    font-size: 20px;
    font-weight: 500;
    border: 1px solid red;
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    background-color: #dc0015;
    transition: all 0.25s;

    &:hover {
      background-color: white;
      color: red;
    }
  }
}
.text-banner-1 {
  font-size: 50px;
  font-family: $font-family-secondary;

  @media (max-width: 1056px) {
    font-size: 40px;
  }

  @media (max-width: 704px) {
    font-size: 35px;
  }

  @media (max-width: 400px) {
    font-size: 20px;
  }

  & a {
    text-decoration: none;
    color: #fff;
  }
}

.text-banner-2 {
  font-size: 30px;

  @media (max-width: 700px) {
    font-size: 20px;
  }

  & a {
    text-decoration: none;
    color: #fff;
  }
}

.banner-ws-43 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  .img-vs-ws43 {
    display: flex;
    transition: all 0.32s;

    a {
      overflow: hidden;
      max-width: 300px;
      margin: 0px 25px 5px 25px;
      padding: 10px;
      transition: all 0.32s;
      text-decoration: none;

      //&:hover{
      //transform: scale(1.1);
      //}

      img {
        width: 290px;

        @media (max-width: 1200px) {
          width: 240px;
        }
        @media (max-width: 1125px) {
          display: none;
        }
      }
    }
  }

  .desc-ws-43 {
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-around;

    @media (max-width: 1125px) {
      flex-direction: column;
    }

    @media (max-width: 862px) {
      align-items: center;
    }

    a {
      text-decoration: none;
      color: white;
    }

    h4 {
      font-size: 56px;
      font-family: $font-family-secondary;

      @media (max-width: 504px) {
        font-size: 40px;
      }

      @media (max-width: 369px) {
        font-size: 30px;
      }
    }

    img {
      width: 180px;
      //margin-right: -28px;
      margin-bottom: -20px;
    }
  }
}
