@import '../../../tokens/tokens.scss';

.user-commentaries {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (max-width: 1310px) {
    max-width: 90%;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid $color-secondary-gray-nielsen;
    //border-radius: .8rem;
    margin-bottom: 7.5px;
    background-color: white;
    padding: 10px;
    margin-top: -2px;
    -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    box-shadow: -2px 4px 7px 0px #d6d6d6;

    @media (max-width: 1310px) {
      width: 100%;
      margin-bottom: 20px;
      align-items: center;
      justify-content: center;
    }

    &>h3 {
      text-align: center;
    }

    &>ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 20px;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      & li {
        border: 1px solid $color-secondary-gray-nielsen;
        //border-radius: .8rem;
        padding: 3px 30px;
        list-style-type: none;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
        transition: .32s;

        @media (max-width: 600px) {
          margin: 10px auto;
          width: 100%;
          text-align: center;
        }

        &.select-t {
          background-color: $color-tertiary-gray-nielsen;

          &:hover {
            background-color: $color-primary-gray-nielsen;
            color: white;
          }

          &.active {
            background-color: $color-primary-gray-nielsen;
            color: white;


            &:hover {
              background-color: $color-primary-gray-nielsen;
              color: white;
            }

          }
        }

        &.select-e {
          background-color: $light-green-niq;


          &:hover {
            background-color: $darker-green-niq;
            color: white;
          }


          &.active {
            background-color: $darker-green-niq;
            color: white;


            &:hover {
              background-color: $darker-green-niq;
              color: white;
            }

          }

        }

        &.select-r {
          background-color: $unique-alert-error-niq;

          &:hover {
            background-color: $error-dark-niq;
            color: white;
          }

          &.active {
            background-color: $error-dark-niq;
            color: white;


            &:hover {
              background-color: $error-dark-niq;
              color: white;
            }

          }
        }

      }
    }
  }

  &__commentary {
    border: 1px solid $color-secondary-gray-nielsen;
    //border-radius: .8rem;
    padding: 20px 10px;
    margin: 7.5px 0;
    background-color: white;
    -webkit-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    -moz-box-shadow: 0px 10px 7px 0px rgba(214, 214, 214, 1);
    box-shadow: -2px 4px 7px 0px #d6d6d6;

    @media (max-width: 1310px) {
      width: 100%;
      margin-bottom: 20px;
      align-items: center;
      justify-content: center;
    }


    &__info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>div {
        display: flex;
        align-items: baseline;
        justify-content: center;

        &>span {
          font-size: 1.5rem;
          color: $color-primary-gray-nielsen;
        }
      }

      & .user-name {
        font-size: 1rem;
        margin-left: 20px;
        text-transform: uppercase;
      }

      & .date-commentary {
        font-style: italic;
        font-family: sans-serif;
        color: $color-primary-gray-nielsen;
      }
    }

    & .user-commentary {
      line-height: 1.7rem;
      font-family: sans-serif;
      font-size: 1rem;
      border-bottom: 1px solid;
      border-color: $color-secondary-gray-nielsen;
      font-style: italic;
      color: $color-primary-gray-nielsen;
    }

    &__satisfaction {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;

      & button {
        padding: 7px 14px;
        background-color: white;
        border: 1px solid $color-secondary-gray-nielsen;
        border-radius: .8rem;
        margin-left: 6px;
      }
    }
  }
}